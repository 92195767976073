<template>
   
  <div style="margin:0px 20px 0px 20px">
    <div style="float:right" v-if="sbann=='0'">
   
             
      <el-button class="filter-item" type="primary"  @click="sb">
        上报
      </el-button>
    </div>
    <iframe :src="url" style="height: 700px;width: 100%" />
  </div>
</template>

<script>
import { getViewUrl } from '@/api/viewUrl'
import { qxsb} from '@/api/report'
import { Message, MessageBox } from 'element-ui'
// import { tableHeight2 } from '@/utils/tableHeight'
export default {
  name: 'Chuku',
  mixins: [],
  inject: ['getList'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tableType: {
      type: String,
      required: true
    },
    sban: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      list: [],
      options: '',
      id: '1',
      sbann:'',
      form: {
        // 大面dan
        pch: ''
      },
      url:'',
      code: '1',
      listLoading: false,
      screenLoading: false
    }
  },
  created() {
    this.getDictEntry()
    this.sbann=this.sban
  },
  methods: {
     sb() {
      
     qxsb({code:this.proid}).then(response => {
      debugger
      this.sbann='1'
       Message({
            message: '上报成功',
            type: 'success',
            duration: 5 * 1000
          })
          this.getList()
      }).catch(response => {
         this.getList()
      })
    },
    getDictEntry() {
      getViewUrl({code:this.proid,tableType:this.tableType}).then(response => {
        
        this.url = response.data
      // this.url='http://43.130.44.169:8081/report//share/share.html?_key=4cad70c556b4ce4f800cf16d49a4cf62bbfd02034c23e3c1839d44467f406183&reportPch=',
       //this.url='http://120.53.27.154:8084/report//share/share.html?_key=9b6c945edf9f1e08c92696020a37569fa239445d6ba50cbf5497bbb4563223f91b135343bf804439a20c9b9a93e414e88fc1a0046541e057b09ac10474a43b53f1a3f9bd460f2283'
        console.log('url:      ' + this.url)
      })
    }
  }
}

</script>

