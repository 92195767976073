import request from '@/utils/request'

export function addReportItem(data) {
  return request({
    url: '/ltcloud/report/addReportItem',
    method: 'post',
    data
  })
}
export function getPageByDeptId(data) {
  return request({
    url: '/ltcloud/report/getPageByDeptId',
    method: 'post',
    data
  })
}
export function getPageByDeptIdls(data) {
  return request({
    url: '/ltcloud/report/getPageByDeptIdls',
    method: 'post',
    data
  })
}
export function getTablePage(data) {
  return request({
    url: '/ltcloud/report/getTablePage',
    method: 'post',
    data
  })
}

export function qxsb(data) {
  return request({
    url: '/ltcloud/report/qxsb',
    method: 'post',
    params: data
  })
}


