import request from '@/utils/request'
export function addLog(data) {
  return request({
    url: '/ltcloud/sysReportLog/addLog',
    method: 'post',
    data
  })
}
export function getLogPage(data) {
  return request({
    url: '/ltcloud/sysReportLog/getLogPage',
    method: 'post',
    data
  })
}