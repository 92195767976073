export const tableHeight = {
  data() {
    return {
      tableHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 230
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.tableHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        that.tableHeight = window.tableHeight - 230
      })()
    }
  }
}

export const tableHeight2 = {
  data() {
    return {
      tableHeight2: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 270
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.tableHeight2 = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        that.tableHeight2 = window.tableHeight2 - 270
      })()
    }
  }
}

export const tableHeight3 = {
  data() {
    return {
      tableHeight3: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 200
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.tableHeight3 = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        that.tableHeight3 = window.tableHeight3 - 200
      })()
    }
  }
}

export const tableHeight4 = {
  data() {
    return {
      tableHeight4: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 240
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.tableHeight4 = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        that.tableHeight4 = window.tableHeight4 - 250
      })()
    }
  }
}

export const tableHeight5 = {
  data() {
    return {
      tableHeight5: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 320
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.tableHeight5 = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        that.tableHeight5 = window.tableHeight5 - 150
      })()
    }
  }
}
