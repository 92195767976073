import request from '@/utils/request'

export function getViewUrl(data) {
  return request({
    url: '/ltcloud/viewUrl/getViewUrl',
    method: 'post',
    params: data
  })
}


